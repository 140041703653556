.react-datepicker {
  width: 100%;
  font-family: unset;
  font-size: 0.95rem;
  border: none;

  &-wrapper {
    display: block;
  }

  &__navigation {
    &--previous,
    &--next {
      height: 3rem;
    }
  }

  &__header {
    width: 100%;
    background-color: #fff;
  }

  &__current-month,
  &-year-header {
    font-size: 1rem !important;
    font-weight: 700;
    color: #333;
  }

  &__day-names {
    display: flex;
    padding: 0.4rem;
  }

  &__day-name {
    width: 100%;
  }

  &__month {
    background-color: #fff;
    margin: 0;
    padding: 0.4rem;

    &-container {
      width: 100%;
    }

    &-text--selected {
      background: var(--chakra-colors-primary);
      font-weight: 700;
      color: #fff;
    }
  }

  &__week {
    display: flex;
  }

  &__day {
    width: 100%;
    color: #333;
    padding: 0.35rem 0;

    &-name {
      color: #333;
    }

    &--selected {
      background: var(--chakra-colors-primary);
      font-weight: 700;
      color: #fff;

      &:hover {
        background: var(--chakra-colors-primary);
      }
    }

    &--keyboard-selected {
      background: none;
    }

    &--disabled {
      opacity: 0.3;
      position: relative;
      &:after {
        content: '';
        transform: rotate(-44.99deg);
        width: 70%;
        border-top: 2px solid #aaa;
        position: absolute;
        top: 50%;
        left: 15%;
      }
    }
  }
}
